import React from 'react';
import { IconButton, Typography, Tooltip } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import TitanDialog from './TitanDialog';
import Stack from '@mui/material/Stack';
import useFibrifyForm from '../Fibrify/hooks/useFibrifyForm';
import InputAdornment from '@mui/material/InputAdornment';

export const TITAN_INPUT_TYPES = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
};

export default function TitanEditableText({
  inputType = TITAN_INPUT_TYPES.STRING,
  text,
  label,
  emptyValue = '-',
  onChangeValue,
  validator,
  enableEditing = true,
  suffix,
  disableReason = '',
}) {
  const { onInputWheel } = useFibrifyForm();

  const [openEditTextDialog, setOpenEditTextDialog] = React.useState(false);
  const [updatedValue, setUpdatedValue] = React.useState(text || '');

  const [isValid, setIsValid] = React.useState(true);

  React.useEffect(() => {
    setUpdatedValue(text || '');
  }, [text]);

  const handleStringChange = (e) => {
    validator && setIsValid(validator(e.target.value));
    setUpdatedValue(e.target.value);
  };

  const handleNumberChange = (e) => {
    validator && setIsValid(validator(e.target.value));
    setUpdatedValue(
      Math.abs(e.target.value) > 0 ? Math.abs(e.target.value) : '',
    );
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography display="inline" variant="body1">
        {text ? text : emptyValue}
        {suffix && text && text.length !== 0 ? (
          <Typography sx={{ ml: 1 }} variant="caption" display="inline">
            {suffix}
          </Typography>
        ) : (
          ''
        )}
      </Typography>
      <Tooltip
        title={!enableEditing ? disableReason : ''}
        disableHoverListener={!disableReason}
        disableTouchListener={!disableReason}
      >
        <div>
          <IconButton
            size="large"
            disabled={!enableEditing}
            onClick={() => setOpenEditTextDialog(true)}
          >
            <CreateIcon />
          </IconButton>
        </div>
      </Tooltip>

      {openEditTextDialog && (
        <TitanDialog
          title={`Edit ${label}`}
          onClose={() => setOpenEditTextDialog(false)}
          maxWidth={inputType === TITAN_INPUT_TYPES.NUMBER ? 'xs' : 'md'}
          fullWidth
          isValid={isValid}
          onSave={() => onChangeValue(updatedValue)}
          resetZIndex
        >
          {inputType === TITAN_INPUT_TYPES.STRING && (
            <TextField
              autoFocus
              label={label}
              type="text"
              value={updatedValue}
              fullWidth
              margin="normal"
              onChange={(e) => handleStringChange(e)}
              variant="outlined"
            />
          )}

          {inputType === TITAN_INPUT_TYPES.NUMBER && (
            <TextField
              autoFocus
              label={label}
              type="number"
              value={updatedValue}
              fullWidth
              margin="normal"
              onChange={(e) => handleNumberChange(e)}
              variant="outlined"
              step=".01"
              onWheel={onInputWheel}
              InputProps={
                suffix
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">{suffix}</InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          )}
        </TitanDialog>
      )}
    </Stack>
  );
}
