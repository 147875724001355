import React, { useContext } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { COMPONENT_STATUSES, ROUTES } from '../../constants';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import ScanComponentDialog from '../BuildPlan/ScanComponentDialog';
import { useTitan } from '../Titan/Titan';
import FuseDialogFusionModulesStep from './FuseDialogFusionModulesStep';
import DialogManufacturingOrdersStep from '../ManufacturingOrders/DialogManufacturingOrdersStep';
import FuseDialogPreformsStep from './FuseDialogPreformsStep';
import FuseDialogReviewStep from './FuseDialogReviewStep';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../assets/icons/cross-dark.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import FusionPlanService from '../../services/FusionPlanService';
import FuseDialogPreFusionCustomFieldsStep from './FuseDialogPreFusionCustomFieldsStep';
import FinalPartService from '../../services/FinalPartService';
import useAsyncEffect from 'use-async-effect';
import ManufacturingOrderService from '../../services/ManufacturingOrderService';
import FuseDialogFusionPlansStep from './FuseDialogFusionPlansStep';

const STEP_KEYS = {
  SELECT_FUSION_PLAN: 'SELECT_FUSION_PLAN',
  SELECT_FUSION_MODULE: 'SELECT_FUSION_MODULE',
  SELECT_MANUFACTURING_ORDER: 'SELECT_MANUFACTURING_ORDER',
  SELECT_PREFORMS: 'SELECT_PREFORMS',
  PRE_FUSION_CUSTOM_FIELDS: 'PRE_FUSION_CUSTOM_FIELDS',
  REVIEW: 'REVIEW',
};

export const FuseDialogContext = React.createContext();
export const useFuseDialog = () => useContext(FuseDialogContext);

export default function FuseDialog({
  fusionPlan,
  manufacturingOrder,
  onLoadToFusionModule,
  onClose,
}) {
  const location = useLocation();
  const { addPageToPageHistory, pushSnackbar } = useTitan();

  const [selectedFusionModule, setSelectedFusionModule] = React.useState();

  const [selectedFusionPlan, setSelectedFusionPlan] = React.useState();
  const [selectedManufacturingOrder, setSelectedManufacturingOrder] =
    React.useState();
  const [manufacturingOrdersAvailable, setManufacturingOrdersAvailable] =
    React.useState(false);
  const [finalPartTypes, setFinalPartTypes] = React.useState([]);
  const [selectedFinalPartTypeId, setSelectedFinalPartTypeId] =
    React.useState();
  const [finalParts, setFinalParts] = React.useState([]);
  const [selectedMould, setSelectedMould] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [openScanDialog, setOpenScanDialog] = React.useState(false);
  const [isExternalHeating, setIsExternalHeating] = React.useState(false);
  const [isAutomatic, setIsAutomatic] = React.useState(true);
  const [isPreformConfig, setIsPreformConfig] = React.useState(true);
  const [isMouldSetup, setIsMouldSetup] = React.useState(true);
  const [isMaterialSetup, setIsMaterialSetup] = React.useState(true);
  const [isValidAdditionalCondition, setIsValidAdditionalCondition] =
    React.useState(true);
  const [openFuseConfirmationDialog, setOpenFuseConfirmationDialog] =
    React.useState(false);

  const [fuseLoading, setFuseLoading] = React.useState(false);

  const [steps, setSteps] = React.useState([]);

  const hasCustomFields = React.useMemo(
    () =>
      selectedFusionPlan && selectedFusionPlan.finalPartTypes
        ? selectedFusionPlan?.finalPartTypes.some(
            (finalPartType) => finalPartType.customFields.length !== 0,
          )
        : false,
    [selectedFusionPlan],
  );

  React.useEffect(() => {
    setSteps([
      {
        key: STEP_KEYS.SELECT_FUSION_PLAN,
        label: 'Select Fusion Plan',
        disabled: !fusionPlan,
      },
      {
        key: STEP_KEYS.SELECT_FUSION_MODULE,
        label: 'Select Fusion Module',
      },
      {
        key: STEP_KEYS.SELECT_MANUFACTURING_ORDER,
        label: 'Select Manufacturing Order',
        disabled:
          !selectedFusionPlan?.projectId || !manufacturingOrdersAvailable,
        disableReason: !selectedFusionPlan?.projectId
          ? 'Fusion Plan is not connected to any Project'
          : !manufacturingOrdersAvailable
          ? "Fusion Plan's Project does not have any Manufacturing Orders"
          : null,
      },
      {
        key: STEP_KEYS.SELECT_PREFORMS,
        label: 'Select Preforms',
      },
      {
        key: STEP_KEYS.PRE_FUSION_CUSTOM_FIELDS,
        label: 'Custom fields',
        disabled: !hasCustomFields,
        disableReason: !hasCustomFields
          ? 'Fusion plan does not have any custom fields'
          : null,
      },
      {
        key: STEP_KEYS.REVIEW,
        label: 'Review',
      },
    ]);
    setActiveStep(fusionPlan ? 1 : 0);
  }, [fusionPlan, hasCustomFields, manufacturingOrdersAvailable]);

  useAsyncEffect(async () => {
    if (!selectedFusionPlan) {
      return;
    }

    const manufacturingOrders =
      await ManufacturingOrderService.getManufacturingOrders({
        fusionPlanIds: [selectedFusionPlan.id],
        pageSize: 1,
      });

    setManufacturingOrdersAvailable(
      manufacturingOrders.pagination.totalCount !== 0,
    );
  }, [selectedFusionPlan]);

  const hasActiveJob = React.useMemo(() => {
    return (
      selectedFusionModule &&
      selectedFusionModule.activeJobs &&
      selectedFusionModule.activeJobs.length !== 0
    );
  }, [selectedFusionModule]);

  const hasActiveFusingJob = React.useMemo(() => {
    if (
      selectedFusionModule &&
      selectedFusionModule.activeJobs &&
      selectedFusionModule.activeJobs.length !== 0
    ) {
      const fusingJob = selectedFusionModule.activeJobs.find(
        (fj) => fj.status === COMPONENT_STATUSES.FUSING,
      );

      return !!fusingJob;
    }

    return false;
  }, [selectedFusionModule]);

  const messageForPush = React.useMemo(() => {
    return (
      <>
        {`Fusion Plan ${selectedFusionPlan?.name} ${
          hasActiveFusingJob ? 'added' : 'sent'
        } to`}
        <Link
          to={{
            pathname: ROUTES.FUSION_MODULE_TAB(
              selectedFusionModule?.id,
              'fusionJobs',
            ),
            state: { from: location.pathname },
          }}
          sx={{
            color: '#fff',
            textDecorationColor: '#fff',
            fontWeight: 'bold',
            margin: '0 5px',
          }}
          component={RouterLink}
          onClick={() =>
            addPageToPageHistory({
              id: `FUSION_MODULE:${selectedFusionModule?.id}`,
              url: ROUTES.FUSION_MODULE_TAB(
                selectedFusionModule?.id,
                'fusionJobs',
              ),
              label: `${
                selectedFusionModule?.name || 'Fusion Module'
              } | Fusion Jobs`,
            })
          }
        >
          {selectedFusionModule?.name}
        </Link>
        {hasActiveFusingJob ? ' queue' : ''}
      </>
    );
  }, [
    selectedFusionPlan,
    selectedFusionModule,
    location.pathname,
    addPageToPageHistory,
    hasActiveFusingJob,
  ]);

  const sendToFusionModule = React.useCallback(async () => {
    const data = {
      fusionModuleId: selectedFusionModule.id,
      // preformIds: [],
      isExternalHeating,
      isAutomatic,
      isMouldSetup,
      isMaterialSetup,
      mouldId: selectedMould ? selectedMould.id : null,
      manufacturingOrderId: selectedManufacturingOrder
        ? selectedManufacturingOrder.id
        : null,
      finalPartsData: finalParts.map((finalPart) => ({
        finalPartTypeId: finalPart.finalPartTypeId,
        preformIds: finalPart.selectedPreforms.map((preform) => preform.id),
        customFields: finalPart.customFields.map((customField) => ({
          finalPartTypeCustomFieldId: customField.finalPartTypeCustomFieldId,
          value: customField.value,
        })),
      })),
    };
    let response;

    setFuseLoading(true);

    if (hasActiveFusingJob) {
      response = await FusionPlanService.addToQueue(
        selectedFusionPlan.id,
        data,
        'v2',
      );
    } else {
      response = await FusionPlanService.fuse(
        selectedFusionPlan.id,
        data,
        'v2',
      );
    }

    pushSnackbar(messageForPush, { variant: 'success' });

    setFuseLoading(false);

    return onLoadToFusionModule(response.fusionJob);
  }, [
    selectedFusionModule,
    isExternalHeating,
    isAutomatic,
    isMouldSetup,
    isMaterialSetup,
    selectedMould,
    selectedManufacturingOrder,
    onLoadToFusionModule,
    finalParts,
  ]);

  const onFuseHandler = React.useCallback(async () => {
    if (!hasActiveFusingJob && hasActiveJob) {
      setOpenFuseConfirmationDialog(true);

      return;
    }

    return sendToFusionModule();
  }, [hasActiveJob, hasActiveFusingJob, sendToFusionModule]);

  const onPrev = React.useCallback(() => {
    if (
      activeStep === 3 &&
      (!selectedFusionPlan.projectId || !manufacturingOrdersAvailable)
    ) {
      setActiveStep(1);
    } else if (activeStep === 5 && !hasCustomFields) {
      setActiveStep(2);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [
    activeStep,
    selectedFusionPlan,
    hasCustomFields,
    manufacturingOrdersAvailable,
  ]);

  const onNext = React.useCallback(() => {
    if (
      activeStep === 1 &&
      (!selectedFusionPlan.projectId || !manufacturingOrdersAvailable)
    ) {
      setActiveStep(3);
      setSteps(
        steps.map((step, index) =>
          index === 2 ? { ...step, completed: false } : step,
        ),
      );
    } else if (activeStep === 3 && !hasCustomFields) {
      setActiveStep(5);
      setSteps(
        steps.map((step, index) =>
          index === 4 ? { ...step, completed: false } : step,
        ),
      );
    } else {
      setActiveStep(activeStep + 1);
    }
  }, [
    activeStep,
    selectedFusionPlan,
    steps,
    manufacturingOrdersAvailable,
    hasCustomFields,
  ]);

  const onSelectManufacturingOrder = React.useCallback(
    (manufacturingOrder) => {
      setSelectedManufacturingOrder(manufacturingOrder);
    },
    [setSelectedManufacturingOrder],
  );

  React.useEffect(() => {
    if (manufacturingOrder) {
      setSelectedManufacturingOrder(manufacturingOrder);
    }
  }, [manufacturingOrder]);

  React.useEffect(() => {
    if (fusionPlan) {
      setSelectedFusionPlan(fusionPlan);
    }
  }, [fusionPlan]);

  const isValid =
    selectedFusionPlan &&
    selectedFusionModule &&
    activeStep === steps.length - 1;
  const isNextValid =
    activeStep === 0
      ? !!selectedFusionPlan
      : !!selectedFusionModule && isValidAdditionalCondition;
  const nextButtonLabel =
    (activeStep === 2 && !selectedManufacturingOrder) ||
    (activeStep === 3 &&
      finalPartTypes.some(
        (fpt) => fpt.selectedPreforms && fpt.selectedPreforms.length === 0,
      ))
      ? 'Skip & Next'
      : 'Next';
  const nextButtonTooltip =
    activeStep === 3 && !selectedMould ? 'Mould has to be selected' : '';

  React.useEffect(() => {
    if (!selectedFusionPlan) {
      return;
    }

    const finalPartTypes = selectedFusionPlan.finalPartTypes
      ? selectedFusionPlan.finalPartTypes.sort((a, b) => a.order - b.order)
      : [];

    setFinalPartTypes(finalPartTypes);
    setSelectedFinalPartTypeId(
      finalPartTypes.length !== 0 ? finalPartTypes[0].id : null,
    );
    setFinalParts(
      finalPartTypes.map((finalPartType) => ({
        finalPartTypeId: finalPartType.id,
        selectedPreforms: [],
        customFields: [],
      })),
    );
  }, [selectedFusionPlan]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: {
            lg: '1200px',
          },
        },
      }}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>{`Load ${
            selectedFusionPlan ? selectedFusionPlan.name : 'Fusion Plan'
          } to Fusion Module`}</Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <img src={CloseIcon} alt="Close icon" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <FuseDialogContext.Provider
          value={{
            selectedFusionPlan,
            setSelectedFusionPlan,

            selectedFusionModule,
            setSelectedFusionModule,

            selectedManufacturingOrder,
            setSelectedManufacturingOrder,

            selectedMould,
            setSelectedMould,

            setOpenScanDialog,

            isValidAdditionalCondition,
            setIsValidAdditionalCondition,

            isExternalHeating,
            setIsExternalHeating,

            isPreformConfig,
            setIsPreformConfig,

            isAutomatic,
            setIsAutomatic,

            isMouldSetup,
            setIsMouldSetup,

            isMaterialSetup,
            setIsMaterialSetup,

            hasActiveFusingJob,
            hasActiveJob,

            finalPartTypes,
            setFinalPartTypes,

            selectedFinalPartTypeId,
            setSelectedFinalPartTypeId,

            finalParts,
            setFinalParts,
          }}
        >
          <Stepper sx={{ mb: 2 }} activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.key} completed={step.completed}>
                {step.disabled ? (
                  <Tooltip title={step.disableReason}>
                    <StepLabel>{step.label}</StepLabel>
                  </Tooltip>
                ) : (
                  <StepLabel>{step.label}</StepLabel>
                )}
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && <FuseDialogFusionPlansStep />}

          {activeStep === 1 && <FuseDialogFusionModulesStep />}

          {activeStep === 2 && (
            <DialogManufacturingOrdersStep
              fusionPlanId={selectedFusionPlan.id}
              selectedManufacturingOrder={selectedManufacturingOrder}
              setSelectedManufacturingOrder={onSelectManufacturingOrder}
            />
          )}

          {activeStep === 3 && <FuseDialogPreformsStep />}

          {activeStep === 4 && <FuseDialogPreFusionCustomFieldsStep />}

          {activeStep === 5 && <FuseDialogReviewStep />}

          {openScanDialog && (
            <ScanComponentDialog
              fusionPlan={selectedFusionPlan}
              onSelect={async (selectedPreforms) =>
                setFinalParts((prev) =>
                  prev.map((finalPart) => {
                    if (!finalPart.id === selectedFinalPartTypeId) {
                      return finalPart;
                    }

                    return {
                      ...finalPart,
                      selectedPreforms,
                    };
                  }),
                )
              }
              onClose={() => {
                setOpenScanDialog(false);
              }}
            />
          )}

          {openFuseConfirmationDialog && (
            <TitanConfirmationDialog
              title="Overwrite current active Fusion Job config?"
              message={`Fusion Module ${selectedFusionModule.name} has active Fusion Job. Are you sure you want to load new Fusion Job?`}
              onConfirm={() => sendToFusionModule()}
              onClose={() => setOpenFuseConfirmationDialog(false)}
              confirmLabel="Load new job"
            />
          )}
        </FuseDialogContext.Provider>
      </DialogContent>
      <DialogActions sx={{ pl: 3, pr: 3 }}>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          {activeStep > 0 && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={onPrev}
              disabled={fusionPlan && activeStep === 1}
            >
              Prev
            </Button>
          )}
          {activeStep < steps.length - 1 && (
            <Tooltip
              title={nextButtonTooltip}
              placement="top-start"
              disableHoverListener={!nextButtonTooltip}
              sx={{ ml: 1 }}
            >
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onNext}
                  disabled={!isNextValid}
                >
                  {nextButtonLabel}
                </Button>
              </span>
            </Tooltip>
          )}
          {sendToFusionModule && (
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              disabled={fuseLoading || !isValid}
              onClick={onFuseHandler}
            >
              {hasActiveFusingJob ? 'Add to queue' : 'Load to FM'}
              {fuseLoading && <CircularProgress sx={{ ml: 1 }} size={24} />}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
