import TitanDialog from '../Titan/TitanDialog';
import { Chip, Grid, MenuItem, TextField } from '@mui/material';
import React from 'react';
import { colors } from '../Theme/vars';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import MaterialTypeService from '../../services/MaterialTypeService';
import MaterialDialog from '../Materials/MaterialDialog';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import OrganizationService from '../../services/OrganizationService';
import { useAuth0 } from '../Authentication/Auth0';
import { MATERIAL_TYPES, SPOOL_TYPES } from '../../constants';
import { PERMISSIONS } from '../../constants/auth0';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import useAsyncEffect from 'use-async-effect';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import TagService from '../../services/TagService';
import { setTags } from '@sentry/react';
import useFibrifyForm from '../Fibrify/hooks/useFibrifyForm';

const useStyles = makeStyles((theme) => ({
  material: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addMaterialType: {
    marginLeft: theme.spacing(1),
  },
}));

export default function SpoolDialog({ onSave, onClose, editableSpool }) {
  const classes = useStyles();
  const { profile, isSuperAdmin, permissions, checkPermissions } = useAuth0();
  const { onInputWheel } = useFibrifyForm();

  const canManageMaterials = checkPermissions(permissions, [
    PERMISSIONS.MANAGE_MATERIAL_TYPES,
  ]);

  const [openMaterialTypeDialog, setOpenMaterialTypeDialog] =
    React.useState(false);

  const [spoolIdentifier, setSpoolIdentifier] = React.useState(
    editableSpool ? editableSpool.spoolIdentifier : '',
  );
  const [tags, setTags] = React.useState(
    editableSpool && editableSpool.tags
      ? editableSpool.tags.map((tag) => tag.name)
      : [],
  );
  const [materialType, setMaterialType] = React.useState(
    editableSpool ? editableSpool.materialType.type : MATERIAL_TYPES.PLASTIC,
  );
  const [materialId, setMaterialId] = React.useState(
    editableSpool ? editableSpool.materialId : '',
  );

  const [originalWeight, setOriginalWeight] = React.useState(
    editableSpool && editableSpool.originalWeight
      ? editableSpool.originalWeight
      : '',
  );
  const [currentWeight, setCurrentWeight] = React.useState(
    editableSpool && editableSpool.currentWeight
      ? editableSpool.currentWeight
      : '',
  );
  const [originalLength, setOriginalLength] = React.useState(
    editableSpool && editableSpool.originalLength
      ? editableSpool.originalLength
      : '',
  );
  const [currentLength, setCurrentLength] = React.useState(
    editableSpool && editableSpool.currentLength
      ? editableSpool.currentLength
      : '',
  );
  const [density, setDensity] = React.useState(
    editableSpool ? editableSpool.density : '',
  );
  const [calculatedDensity, setCalculatedDensity] = React.useState(
    editableSpool ? editableSpool.density : '',
  );
  const [currentMaterialType, setCurrentMaterialType] = React.useState('');

  const [spoolType, setSpoolType] = React.useState(
    editableSpool ? editableSpool.type : null,
  ); // Material dependent

  const [batchSize, setBatchSize] = React.useState(
    editableSpool ? editableSpool.batchSize : '',
  );

  const [quality, setQuality] = React.useState(
    editableSpool ? editableSpool.quality : 0,
  );
  const [comment, setComment] = React.useState(
    editableSpool ? editableSpool.comment : '',
  );
  const [materialTypes, setMaterialTypes] = React.useState([]);

  const [organizationId, setOrganizationId] = React.useState(
    editableSpool ? editableSpool.organizationId : profile.organizationId,
  );
  const [organizations, setOrganizations] = React.useState([]);

  const [availableTags, setAvailableTags] = React.useState([]);

  const loadMaterialTypes = React.useCallback(async () => {
    const { data } = await MaterialTypeService.getMaterialTypes({
      type: materialType,
      page: 0,
      pageSize: 1000,
    });
    setMaterialTypes(data);
  }, [materialType]);

  const loadOrganizations = async () => {
    const { data } = await OrganizationService.getOrganizations();
    setOrganizations(data);
  };

  const loadAvailableTags = async () => {
    const { tags } = await TagService.getTags({ tagType: 'SPOOL' });

    setAvailableTags(tags.map((tag) => tag.name));
  };

  const isPolymerTypeSelected = React.useMemo(() => {
    // remove plastic later
    return (
      currentMaterialType === 'POLYMER' || currentMaterialType === 'PLASTIC'
    );
  }, [currentMaterialType]);

  React.useEffect(() => {
    if (currentMaterialType === 'FIBER') {
      setSpoolType(SPOOL_TYPES.SPOOL);
    } else {
      setSpoolType(editableSpool ? editableSpool.type : SPOOL_TYPES.SPOOL);
    }
  }, [currentMaterialType]);

  React.useEffect(() => {
    if (materialTypes) {
      setCurrentMaterialType(
        materialTypes.find((type) => type.id === materialId)?.type,
      );
    }
  }, [materialId, materialTypes]);

  useAsyncEffect(async () => {
    await loadMaterialTypes();
  }, [materialType]);

  useAsyncEffect(async () => {
    if (isSuperAdmin) {
      await loadOrganizations();
    }

    await loadAvailableTags();
  }, []);

  const onSubmitForm = () => {
    const data = {
      spoolIdentifier,
      organizationId,
      materialId,
      originalWeight: originalWeight || null,
      currentWeight: currentWeight || null,
      originalLength: originalLength || null,
      currentLength: currentLength || null,
      density: density || null,
      comment,
      quality,
      tags: tags,
    };

    if (spoolType) {
      data.type = spoolType;

      if (spoolType === SPOOL_TYPES.BATCH) {
        data.batchSize = batchSize;
      }
    }

    return onSave(data);
  };

  React.useEffect(() => {
    setCalculatedDensity(
      originalWeight &&
        originalLength &&
        originalWeight.length !== 0 &&
        originalLength.length !== 0
        ? parseFloat(originalWeight) / parseFloat(originalLength)
        : null,
    );
  }, [originalWeight, originalLength]);

  const densityString = density ? parseFloat(density).toString() : '';
  const densityParts = densityString.split('.');
  const countDecimals = densityParts.length === 2 ? densityParts[1].length : 0;
  const decimalMultiplicator = 10 ** Math.min(4, countDecimals);
  const calculatedDensityString = calculatedDensity
    ? countDecimals !== 0
      ? (
          Math.round(calculatedDensity * decimalMultiplicator) /
          decimalMultiplicator
        ).toString()
      : calculatedDensity.toString()
    : '';

  const maxDensityLength =
    density > 1 ? densityString.length : Math.min(6, densityString.length);

  const densityWarning =
    calculatedDensity &&
    density &&
    calculatedDensityString.substring(0, maxDensityLength) !==
      densityString.substring(0, maxDensityLength);

  return (
    <>
      <TitanDialog
        title={`${editableSpool ? 'Edit' : 'Create'} Spool`}
        maxWidth="sm"
        onClose={onClose}
        onSave={onSubmitForm}
        saveButtonLabel={editableSpool ? 'Save' : 'Create'}
        isValid={
          !!spoolIdentifier && !!materialId && !!quality && !!organizationId
        }
      >
        <TextField
          required
          label="Spool Identifier"
          placeholder="Spool Identifier"
          value={spoolIdentifier}
          onChange={(e) => setSpoolIdentifier(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Autocomplete
          multiple
          id="spool-tags"
          value={tags}
          onChange={(e, values) => setTags(values)}
          options={availableTags}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          // getOptionDisabled={() => tags.length > 0}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              {...params}
              label="Tag"
              placeholder={tags.length === 0 ? 'Tag' : ''}
              margin="normal"
              inputProps={{ ...inputProps, disabled: tags.length > 0 }}
            />
          )}
          onBlur={(event) => {
            if (event.target.value.trim() !== '') {
              setTags((prev) => [...prev, event.target.value]);
            }
          }}
          clearOnBlur
        />
        {isSuperAdmin ? (
          <FormControl fullWidth margin="normal">
            <TextField
              select
              label="Organization"
              required
              fullWidth
              value={organizationId}
              onChange={(e) => setOrganizationId(e.target.value)}
            >
              {organizations.map((organization) => (
                <MenuItem key={organization.id} value={organization.id}>
                  {organization.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        ) : (
          ''
        )}
        <FormControl>
          <FormLabel>Material Type</FormLabel>
          <RadioGroup
            row
            value={materialType}
            onChange={(e) => setMaterialType(e.target.value)}
          >
            <FormControlLabel
              value={MATERIAL_TYPES.PLASTIC}
              control={<Radio />}
              label="Polymer"
            />
            <FormControlLabel
              value={MATERIAL_TYPES.FIBER}
              control={<Radio />}
              label="Fiber"
            />
          </RadioGroup>
        </FormControl>
        <div className={classes.material}>
          <FormControl fullWidth margin="normal">
            <TextField
              select
              label="Material"
              required
              fullWidth
              value={materialId}
              onChange={(e) => setMaterialId(e.target.value)}
              InputLabelProps={{ shrink: !!materialId }}
            >
              {materialTypes.map((material) => (
                <MenuItem key={material.id} value={material.id}>
                  {material.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <IconButton
            disabled={!canManageMaterials}
            onClick={() => setOpenMaterialTypeDialog(true)}
            className={classes.addMaterialType}
          >
            <AddIcon />
          </IconButton>
        </div>
        {isPolymerTypeSelected && (
          <div className={classes.materialType}>
            <FormControl fullWidth margin="normal">
              <TextField
                select
                label="Spool Type"
                required
                fullWidth
                value={spoolType}
                onChange={(e) => {
                  setSpoolType(e.target.value);
                }}
                InputLabelProps={{ shrink: !!spoolType }}
              >
                {Object.values(SPOOL_TYPES).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type[0] + type.slice(1).toLowerCase()}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        )}
        {spoolType === SPOOL_TYPES.BATCH && (
          <div className={classes.materialType}>
            <FormControl fullWidth margin="normal">
              <TextField
                type="number"
                label="Batch Size"
                placeholder="Batch Size"
                required
                fullWidth
                value={batchSize}
                onChange={(e) => setBatchSize(e.target.value)}
                onWheel={onInputWheel}
              />
            </FormControl>
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              type="number"
              label="Original Weight"
              placeholder="Original Weight"
              value={originalWeight}
              onChange={(e) => {
                setOriginalWeight(e.target.value);
                setCurrentWeight(e.target.value);
              }}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              onWheel={onInputWheel}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              type="number"
              label="Actual Weight"
              placeholder="Actual Weight"
              value={currentWeight}
              onChange={(e) => setCurrentWeight(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              onWheel={onInputWheel}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              type="number"
              label="Original Length"
              placeholder="Original Length"
              value={originalLength}
              onChange={(e) => {
                setOriginalLength(e.target.value);
                setCurrentLength(e.target.value);
              }}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              onWheel={onInputWheel}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              type="number"
              label="Current Length"
              placeholder="Current Length"
              value={currentLength}
              onChange={(e) => {
                setCurrentLength(e.target.value);
              }}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              onWheel={onInputWheel}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              type="number"
              label="Average linear density"
              placeholder="Average linear density"
              value={density}
              onChange={(e) => {
                setDensity(e.target.value);
              }}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">g/m</InputAdornment>
                ),
              }}
              helperText={
                calculatedDensity
                  ? `Calculated ${
                      Math.round(calculatedDensity * 10000) / 10000
                    }`
                  : ''
              }
              color={densityWarning ? 'warning' : ''}
              focused={densityWarning}
              onWheel={onInputWheel}
            />
          </Grid>
        </Grid>

        <>
          <Typography
            variant="body2"
            style={{
              marginTop: 10,
              fontSize: 14,
              color: colors.RED,
            }}
          >
            Quality
          </Typography>
          <Rating
            value={quality}
            onChange={(event, quality) => {
              setQuality(quality);
            }}
          />
        </>
        <Typography
          variant="body2"
          style={{
            marginTop: 10,
            fontSize: 14,
            color: colors.RED,
          }}
        >
          Add a comment
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </TitanDialog>

      {openMaterialTypeDialog && (
        <MaterialDialog
          onSave={async (data) => {
            const materialType = await MaterialTypeService.createMaterialType(
              data,
            );
            await loadMaterialTypes();
            setMaterialId(materialType.id);
          }}
          onClose={() => {
            setOpenMaterialTypeDialog(false);
          }}
        />
      )}
    </>
  );
}
