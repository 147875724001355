import React, { useCallback, useState } from 'react';
import { useTitan } from '../../Titan/Titan';
import MeasuringDeviceService from '../../../services/MeasuringDeviceService';
import useAsyncEffect from 'use-async-effect';
import useWebSocket from 'react-use-websocket';
import { WEB_SOCKET_ACTIONS } from '../../../constants';

export default function useMeasuringDevices({
  getWebSocketUrl,
  getWebSocketOptions,
}) {
  const [loadingMeasuringDevices, setLoadingMeasuringDevices] = useState(true);
  const [measuringDevices, setMeasuringDevices] = useState([]);

  const loadMeasuringDevices = useCallback(async () => {
    setLoadingMeasuringDevices(true);
    const { measuringDevices } =
      await MeasuringDeviceService.getMeasuringDevices({});

    setMeasuringDevices(measuringDevices);

    setLoadingMeasuringDevices(false);
  }, []);

  useAsyncEffect(async () => {
    await loadMeasuringDevices();
  }, [loadMeasuringDevices]);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.MEASUREMENTS &&
      lastJsonMessage.data &&
      lastJsonMessage.data.measuringDeviceId &&
      lastJsonMessage.data.measurements
    ) {
      const { measuringDeviceId, measurements } = lastJsonMessage.data;

      setMeasuringDevices((prev) =>
        prev.map((measuringDevice) => {
          if (measuringDevice.id !== measuringDeviceId) {
            return measuringDevice;
          }

          return {
            ...measuringDevice,
            measurements,
          };
        }),
      );
    }
  }, [lastJsonMessage]);

  return { loadingMeasuringDevices, measuringDevices };
}
