import React from 'react';
import useWebSocket from 'react-use-websocket';
import { COMPONENT_STATUSES, WEB_SOCKET_ACTIONS } from '../../constants';
import { useTitan } from '../Titan/Titan';
import useAsyncEffect from 'use-async-effect';
import FusorService from '../../services/FusorService';

export default function useFusionModuleState(
  fusionModuleId,
  fusionModule = null,
) {
  const { getWebSocketUrl, getWebSocketOptions } = useTitan();
  const [state, setState] = React.useState(
    fusionModule?.state ? fusionModule.state.state : COMPONENT_STATUSES.OFFLINE,
  );
  const [progress, setProgress] = React.useState(
    fusionModule?.state ? fusionModule.state.progress : 0,
  );
  const [fusionModuleVersion, setFusionModuleVersion] = React.useState(
    fusionModule?.state ? fusionModule.state.fusionModuleVersion : '',
  );

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.FUSION_MODULE_STATE &&
      lastJsonMessage.fusionModuleId === fusionModuleId
    ) {
      setState(lastJsonMessage.state);
      setProgress(lastJsonMessage.progress);
      setFusionModuleVersion(lastJsonMessage.fusionModuleVersion);
    }
  }, [lastJsonMessage]);

  useAsyncEffect(async () => {
    if (!fusionModuleId || fusionModule) {
      return;
    }

    const fusionModuleData = await FusorService.getFusor(fusionModuleId);

    if (fusionModuleData) {
      setState(fusionModuleData.state.state);
      setProgress(fusionModuleData.state.progress);
      setFusionModuleVersion(fusionModuleData.state.fusionModuleVersion);
    }
  }, [fusionModuleId, fusionModule]);

  return {
    state,
    setState,
    progress,
    setProgress,
    fusionModuleVersion,
    setFusionModuleVersion,
  };
}
