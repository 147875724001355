import React from 'react';
import Grid from '@mui/material/Grid';
import { QrReader } from 'react-qr-reader';
import ComponentService from '../../services/ComponentService';
import TitanDataGrid from '../TitanDataGrid/TitanDataGrid';
import TitanDialog from '../Titan/TitanDialog';
import { COMPONENT_STATUSES } from '../../constants';
import WarningIcon from '@mui/icons-material/Warning';

export default function ScanComponentDialog({ fusionPlan, onSelect, onClose }) {
  const [preforms, setPreforms] = React.useState([]);
  const scannedTextRef = React.useRef('');

  const [selectedPreforms, setSelectedPreforms] = React.useState([]);

  const onSelectionModelChange = React.useCallback((newSelectedPreforms) => {
    setSelectedPreforms(newSelectedPreforms);
  }, []);

  const isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const handleScan = React.useCallback(async (result) => {
    if (!result?.text || scannedTextRef.current === result.text) {
      return;
    }

    scannedTextRef.current = result.text;

    const data = result.text;

    if (data && isValidHttpUrl(data)) {
      const preformId = data.split('/').pop();

      const preform = await ComponentService.getComponent(preformId, {
        withRelated: ['buildJob', 'printer', 'buildPlan'],
      });

      setPreforms((prev) => [
        ...prev.filter((p) => p.id !== preform.id),
        preform,
      ]);

      if (preform.status === COMPONENT_STATUSES.BUILT) {
        setSelectedPreforms((prev) => [
          ...prev.filter((p) => p.id !== preform.id),
          preform,
        ]);
      }
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => (row.preformKey ? row.preformKey : row.id),
      },
      {
        headerName: 'Build Plan',
        field: 'buildPlanName',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (row.buildPlan ? row.buildPlan.name : ''),
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 150,
        sortable: false,
      },
      {
        headerName: '',
        field: 'messages',
        minWidth: 20,
        sortable: false,
        renderCell: ({ row }) =>
          row.status !== COMPONENT_STATUSES.BUILT ? (
            <WarningIcon color="warning" />
          ) : (
            ''
          ),
      },
    ],
    [],
  );

  const handleSave = React.useCallback(() => {
    return onSelect(selectedPreforms);
  }, [selectedPreforms]);

  return (
    <TitanDialog
      open={true}
      title={`Fuse Build ${fusionPlan.name}`}
      onClose={onClose}
      onSave={handleSave}
      isValid={selectedPreforms.length !== 0}
      maxWidth="md"
      saveButtonLabel="Select"
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <QrReader
            constraints={{
              facingMode: 'environment',
            }}
            scanDelay={100}
            onResult={handleScan}
            style={{ height: 320, width: 320 }}
          />
        </Grid>
        <Grid item xs={8}>
          <TitanDataGrid
            rows={preforms}
            rowCount={preforms.length}
            columns={columns}
            showSearch={false}
            hideFooter={true}
            selectedRows={selectedPreforms}
            onSelectionModelChange={onSelectionModelChange}
            checkboxMultiSelection
            isRowSelectable={({ row }) =>
              row.status === COMPONENT_STATUSES.BUILT
            }
          />
        </Grid>
      </Grid>
    </TitanDialog>
  );
}
