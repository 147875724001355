import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ContactSupportOutlinedIcon from '../assets/icons/support-icon.svg';
import Avatar from '@mui/material/Avatar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { PERMISSIONS } from '../constants/auth0';
import Can from './Authentication/Can';
import { useAuth0 } from './Authentication/Auth0';
import { ROUTES, ROLES } from '../constants';
import 'typeface-comfortaa';
import menuLogo from '../assets/menu-logo.png';
import MenuIcon from '../assets/icons/menu.svg';
import BuildModuleIcon from '../assets/icons/build-module-small.svg';
import FusionModuleIcon from '../assets/icons/fusion-module-small.svg';
import LibraryIcon from '../assets/icons/library.svg';
import OrganizationProfileIcon from '../assets/icons/organization-profile.svg';
import FibrifyDesignSuiteIcon from '../assets/icons/fibrify-design-suite.svg';
import DashboardIcon from '../assets/icons/dashboard-icon.svg';
import FilePackagesIcon from '../assets/icons/file-packages-icon.svg';
import LogoutIcon from '../assets/icons/logout.svg';
import CrossIcon from '../assets/icons/cross.svg';
import DeviceLogsIcon from '../assets/icons/device-logs-icon.svg';
import TermsAndConditionsIcon from '../assets/icons/terms-and-conditions-icon.svg';
import KnowledgeBaseIcon from '../assets/icons/knowledge-base-icon.svg';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import OpenSourceIcon from '../assets/icons/open-source-icon.svg';
import { colors } from './Theme/vars';
import LoggedAsMember from './Profile/LoggedAsMember';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TitanPopover from './Titan/TitanPopover';
import { useHistory } from 'react-router-dom';
import { useTitan } from './Titan/Titan';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import TitanRecentPages from './Titan/TitanRecentPages';
import FactoryIcon from '@mui/icons-material/Factory';
import FibrifySearchBar from './FibrifySearchBar/FibrifySearchBar';
import MeasuringDevicesPanel from './MeasuringDevicesPanel/MeasuringDevicesPanel';
import StraightenIcon from '@mui/icons-material/Straighten';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QRCodeScannerDialog from './QRCodeScannerDialog/QRCodeScannerDialog';

const ListItemLink = ({ primary, to, icon, disabled, className, onClick }) => {
  return (
    <ListItem
      className={className}
      disabled={disabled}
      button
      to={to}
      component={Link}
      onClick={() => {
        if (onClick) {
          return onClick();
        }

        return false;
      }}
    >
      <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>{icon}</ListItemIcon>
      <ListItemText
        primary={primary}
        primaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  );
};

export default function WebappMenu() {
  const history = useHistory();
  const { isAuthenticated, permissions, roles, profile, logout } = useAuth0();
  const {
    apiVersion,
    organizationLogo,
    pageHistory,
    organization: { measuringDevicesEnabled = false },
  } = useTitan();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openMeasuringDevicesDrawer, setOpenMeasuringDevicesDrawer] =
    React.useState(false);
  const [openQRCodeScannerDialog, setOpenQRCodeScannerDialog] =
    React.useState(false);

  const onLogoClick = () => {
    if (permissions.indexOf(PERMISSIONS.READ_BUILD_PLANS) !== -1) {
      return history.push(ROUTES.DASHBOARD);
    }

    return history.push(ROUTES.HOME_PAGE);
  };

  let isSuperAdmin = false;
  let isOrganizationAdmin = false;
  let generalTerms = false;

  if (profile) {
    isSuperAdmin =
      roles && roles.find((r) => r.id === ROLES.SUPER_ADMIN) != null;

    isOrganizationAdmin =
      roles && roles.find((r) => r.id === ROLES.ADMIN) != null;

    generalTerms = profile.loggedAs
      ? profile.loggedAsMember.generalTerms
      : profile.generalTerms;
  }

  return (
    <>
      <AppBar position="sticky" sx={{ bgcolor: 'black' }}>
        <Toolbar>
          {isAuthenticated && (
            <IconButton
              className="navbar-menu-button"
              color="inherit"
              aria-label="Menu"
              disabled={!generalTerms}
              onClick={() => setOpenDrawer(true)}
            >
              <img src={openDrawer ? CrossIcon : MenuIcon} alt="Menu icon" />
            </IconButton>
          )}
          <Box
            className="logo-button"
            onClick={onLogoClick}
            sx={{
              ml: 2,
              height: '64px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            {organizationLogo ? (
              <img
                src={organizationLogo}
                alt="Logo"
                style={{ height: '40%' }}
              />
            ) : (
              <img src={menuLogo} alt="Logo" style={{ height: '40%' }} />
            )}
          </Box>

          {isAuthenticated && !isSuperAdmin && (
            <TitanRecentPages recentPages={pageHistory} />
          )}
          <Box sx={{ flexGrow: 1 }} />
          {isAuthenticated && (
            <FibrifySearchBar
              setOpenQRCodeScannerDialog={setOpenQRCodeScannerDialog}
            />
          )}
          {isAuthenticated && measuringDevicesEnabled ? (
            <IconButton
              className="navbar-menu-button"
              color="inherit"
              sx={{ mr: 2 }}
              onClick={() =>
                setOpenMeasuringDevicesDrawer(!openMeasuringDevicesDrawer)
              }
            >
              <StraightenIcon />
            </IconButton>
          ) : (
            ''
          )}
          <Box sx={{ display: 'flex' }}>
            <TitanPopover
              icon={InfoOutlinedIcon}
              fillIcon="white"
              className={'app-versions'}
            >
              <List>
                <ListItem>
                  <ListItemText
                    className={'web-version'}
                    primary={`WEB version: v${process.env.REACT_APP_VERSION}${
                      process.env.REACT_APP_ENVIRONMENT_TYPE !== 'production'
                        ? `-${process.env.REACT_APP_GIT_HASH}`
                        : ''
                    }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className={'api-version'}
                    primary={`API version: v${apiVersion}`}
                  />
                </ListItem>
              </List>
            </TitanPopover>
            {isAuthenticated && (
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <LoggedAsMember />
                {/*<NotificationsMenu />*/}
                <Box>
                  <IconButton
                    color="inherit"
                    onClick={() => history.push(ROUTES.PROFILE)}
                    className={'profile-page-button'}
                  >
                    <Avatar src={profile ? profile.avatarLink : ''} />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    className={'logout-button'}
                    onClick={() => logout()}
                  >
                    <img src={LogoutIcon} alt="Logout Icon" />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        sx={{
          '& .MuiDrawer-paper': {
            top: 64,
            width: 350,
            height: 'calc(100vh - 64px)',
          },

          '& .MuiBackdrop-root': {
            backgroundColor: 'inherit',
          },
        }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => {
          if (generalTerms) {
            setOpenDrawer(true);
          }
        }}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={() => setOpenDrawer(false)}
          onKeyDown={() => setOpenDrawer(false)}
        >
          <Box>
            <List>
              {(!isSuperAdmin || (isSuperAdmin && profile.loggedAsMember)) && (
                <>
                  <Can
                    permissions={[
                      PERMISSIONS.READ_BUILD_MODULE,
                      PERMISSIONS.READ_FUSION_MODULE,
                    ]}
                    matchAll
                    yes={() => (
                      <>
                        <ListItem>
                          <ListItemText
                            style={{ color: colors.GREY }}
                            primary="Devices"
                            primaryTypographyProps={{
                              variant: 'overline',
                            }}
                          />
                        </ListItem>
                        <ListItemLink
                          className={classNames(['build-modules-link'])}
                          to={ROUTES.BUILD_MODULES}
                          primary="Build Modules"
                          icon={
                            <img src={BuildModuleIcon} alt="Build Modules" />
                          }
                        />
                        <ListItemLink
                          className={classNames(['fusion-modules-link'])}
                          to={ROUTES.FUSION_MODULES}
                          primary="Fusion Modules"
                          icon={
                            <img src={FusionModuleIcon} alt="Fusion Modules" />
                          }
                        />
                      </>
                    )}
                  />
                  <ListItem>
                    <ListItemText
                      style={{ color: colors.GREY }}
                      primary="Production workflow"
                      primaryTypographyProps={{
                        variant: 'overline',
                      }}
                    />
                  </ListItem>
                  <Can
                    permissions={[PERMISSIONS.READ_BUILD_PLANS]}
                    yes={() => (
                      <ListItemLink
                        className={classNames(['dashboard-link'])}
                        to={ROUTES.DASHBOARD}
                        primary="Dashboard"
                        icon={<img src={DashboardIcon} alt="" />}
                      />
                    )}
                  />
                  <Can
                    permissions={[PERMISSIONS.READ_BUILD_PLANS]}
                    yes={() => (
                      <ListItemLink
                        className={classNames(['build-plans-link'])}
                        to={ROUTES.BUILD_PLANS('page', 0)}
                        primary="Build Plans"
                        icon={<img src={FilePackagesIcon} alt="" />}
                      />
                    )}
                  />
                  <Can
                    permissions={[PERMISSIONS.READ_FUSION_PLANS]}
                    yes={() => (
                      <ListItemLink
                        className={classNames(['fusion-plans-link'])}
                        to={ROUTES.FUSION_PLANS('page', 0)}
                        primary="Fusion Plans"
                        icon={<img src={LibraryIcon} alt="" />}
                      />
                    )}
                  />
                  <Can
                    permissions={[
                      PERMISSIONS.MANAGE_ASSEMBLIES,
                      PERMISSIONS.MANAGE_COMPONENTS,
                    ]}
                    yes={() => (
                      <ListItemLink
                        className={classNames(['device-logs-link'])}
                        to={ROUTES.HISTORY('page', 0)}
                        primary="History"
                        icon={<img src={DeviceLogsIcon} alt="" />}
                      />
                    )}
                  />
                  <Can
                    permissions={[
                      PERMISSIONS.MANAGE_MOULDS,
                      PERMISSIONS.MANAGE_SPOOLS,
                      PERMISSIONS.MANAGE_MATERIAL_TYPES,
                    ]}
                    yes={() => (
                      <ListItemLink
                        className={classNames(['gears-link'])}
                        to={ROUTES.GEAR_TAB('materials')}
                        primary="Gear"
                        icon={<SettingsIcon sx={{ fill: '#000' }} />}
                      />
                    )}
                  />
                  <Can
                    permissions={[PERMISSIONS.READ_PROJECTS]}
                    yes={() => (
                      <ListItemLink
                        className={classNames(['projects-link'])}
                        to={ROUTES.PROJECTS()}
                        primary="Projects"
                        icon={<AccountTreeIcon sx={{ fill: '#000' }} />}
                      />
                    )}
                  />
                  <Can
                    permissions={[PERMISSIONS.READ_MANUFACTURING_ORDERS]}
                    yes={() => (
                      <ListItemLink
                        className={classNames(['manufacturing-orders-link'])}
                        to={ROUTES.MANUFACTURING_ORDERS()}
                        primary="Manufacturing Orders"
                        icon={<FactoryIcon sx={{ fill: '#000' }} />}
                      />
                    )}
                  />
                </>
              )}
              <ListItem>
                <ListItemText
                  style={{ color: colors.GREY }}
                  primary="Management"
                  primaryTypographyProps={{
                    variant: 'overline',
                  }}
                />
              </ListItem>
              {isSuperAdmin && (
                <ListItemLink
                  className={classNames(['admin-dashboard-link'])}
                  to={ROUTES.ADMIN_DASHBOARD_TAB_PAGE('organizations', 0)}
                  primary="Admin Dashboard"
                  icon={<img src={OrganizationProfileIcon} alt="" />}
                />
              )}
              {isOrganizationAdmin && (
                <ListItemLink
                  className={classNames(['organization-dashboard-link'])}
                  to={ROUTES.ORGANIZATION_TAB_PAGE(
                    profile.loggedAs
                      ? profile.loggedAsMember.organizationId
                      : profile.organizationId,
                    'users',
                    0,
                  )}
                  primary="Organization Dashboard"
                  icon={<img src={OrganizationProfileIcon} alt="" />}
                />
              )}
              <Can
                permissions={[
                  PERMISSIONS.MANAGE_FDS_RELEASES,
                  PERMISSIONS.READ_FDS_RELEASES,
                ]}
                yes={() => (
                  <ListItemLink
                    className={classNames(['fds-releases-link'])}
                    to={ROUTES.FDS_RELEASES_PAGE(0)}
                    primary="fDS Releases"
                    icon={
                      <img src={FibrifyDesignSuiteIcon} alt="fDS Releases" />
                    }
                  />
                )}
              />
              {!isSuperAdmin && (
                <ListItemLink
                  className={classNames(['terms-and-conditions-link'])}
                  to={ROUTES.TERMS_AND_CONDITIONS}
                  primary="Terms and Conditions"
                  icon={<img src={TermsAndConditionsIcon} alt="" />}
                />
              )}
              <ListItemLink
                className={classNames(['knowledge-base-link'])}
                primary="Knowledge Base"
                to={ROUTES.DASHBOARD}
                icon={<img src={KnowledgeBaseIcon} alt="" />}
                onClick={() => {
                  window.open('https://knowledgebase.9tlabs.com', '_blank');
                }}
              />
              <ListItemLink
                className={classNames(['terms-and-conditions-link'])}
                to={ROUTES.LICENSES_OPEN_SOURCE}
                primary="Open Source licenses"
                icon={<img src={OpenSourceIcon} alt="" />}
              />
              <ListItemLink
                className={classNames(['support-request-link'])}
                to={ROUTES.SUPPORT}
                primary="Support Request"
                icon={<img src={ContactSupportOutlinedIcon} alt="" />}
              />
            </List>
          </Box>
        </div>
      </SwipeableDrawer>
      {measuringDevicesEnabled && (
        <SwipeableDrawer
          anchor="right"
          variant="persistent"
          sx={{
            '& .MuiDrawer-paper': {
              top: 64,
              width: 350,
              height: 'calc(100vh - 64px)',
            },

            '& .MuiBackdrop-root': {
              backgroundColor: 'inherit',
            },
          }}
          open={openMeasuringDevicesDrawer}
          onClose={() => setOpenMeasuringDevicesDrawer(false)}
          onOpen={() => {}}
        >
          <MeasuringDevicesPanel />
        </SwipeableDrawer>
      )}

      {openQRCodeScannerDialog && (
        <QRCodeScannerDialog
          onClose={() => setOpenQRCodeScannerDialog(false)}
        />
      )}
    </>
  );
}
