import React from 'react';
import { useTitan } from '../Titan/Titan';
import TitanPage from '../Titan/TitanPage';
import { ROUTES } from '../../constants';
import { useHistory, useParams } from 'react-router-dom';
import ManufacturingOrdersTable from './ManufacturingOrdersTable';
import ProjectService from '../../services/ProjectService';
import ManufacturingOrdersTableFilter from './ManufacturingOrdersTableFilter';
import ManufacturingOrdersTableChips from './ManufacturingOrdersTableChips';

import { MANUFACTURING_ORDER_STATES } from '../../constants';
import { useAuth0 } from '../Authentication/Auth0';

export default function ManufacturingOrdersPage() {
  const history = useHistory();
  const { page = 0 } = useParams();
  const { addPageToPageHistory } = useTitan();
  const { currentMemberId } = useAuth0();

  const [projects, setProjects] = React.useState([]);
  const [selectedProjects, setSelectedProjects] = React.useState([]);
  const [selectedStates, setSelectedStates] = React.useState({
    [MANUFACTURING_ORDER_STATES.OPEN]: true,
    [MANUFACTURING_ORDER_STATES.IN_PROGRESS]: true,
    [MANUFACTURING_ORDER_STATES.DONE]: true,
    [MANUFACTURING_ORDER_STATES.PAUSED]: true,
    [MANUFACTURING_ORDER_STATES.ABORTED]: true,
  });
  const [manufacturingOrderType, setManufacturingOrderType] =
    React.useState('all');
  const [manufacturingOrderProgress, setManufacturingOrderProgress] =
    React.useState('all');
  const [filterAssignedMOs, setFilterAssignedMOs] = React.useState(false);

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Manufacturing orders',
        disabled: true,
      },
    ],
    [],
  );

  React.useEffect(() => {
    addPageToPageHistory({
      id: `MANUFACTURING_ORDERS`,
      url: ROUTES.MANUFACTURING_ORDERS_PAGE(page),
      label:
        page === 0
          ? `Manufacturing Orders`
          : `Manufacturing Orders | Page : ${parseInt(page, 10) + 1}`,
    });
    history.push(ROUTES.MANUFACTURING_ORDERS_PAGE(page));
  }, [page]);

  React.useEffect(() => {
    ProjectService.getProjects({ pageSize: 1000 }).then(({ data }) => {
      setProjects(data);
    });
  }, []);

  const hiddenColumns = React.useMemo(() => ['odoo'], []);
  const projectIds = React.useMemo(
    () => selectedProjects.map((project) => project.id),
    [selectedProjects],
  );
  const states = React.useMemo(
    () => Object.keys(selectedStates).filter((state) => selectedStates[state]),
    [selectedStates],
  );

  const assigneeIds = React.useMemo(
    () => (filterAssignedMOs ? [currentMemberId] : null),
    [filterAssignedMOs, currentMemberId],
  );

  return (
    <TitanPage title="Manufacturing Orders" breadcrumbs={breadcrumbs}>
      <ManufacturingOrdersTable
        hiddenColumns={hiddenColumns}
        projectIds={projectIds}
        states={states}
        manufacturingOrderType={manufacturingOrderType}
        manufacturingOrderProgress={manufacturingOrderProgress}
        assigneeIds={assigneeIds}
        filtersContent={
          <ManufacturingOrdersTableFilter
            projects={projects}
            selectedProjects={selectedProjects}
            setSelectedProjects={(e, values) =>
              setSelectedProjects([...values])
            }
            selectedStates={selectedStates}
            setSelectedStates={setSelectedStates}
            manufacturingOrderType={manufacturingOrderType}
            setManufacturingOrderType={setManufacturingOrderType}
            manufacturingOrderProgress={manufacturingOrderProgress}
            setManufacturingOrderProgress={setManufacturingOrderProgress}
            filterAssignedMOs={filterAssignedMOs}
            setFilterAssignedMOs={setFilterAssignedMOs}
          />
        }
        chipsContent={
          <ManufacturingOrdersTableChips
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
            selectedStates={selectedStates}
            setSelectedStates={setSelectedStates}
            manufacturingOrderType={manufacturingOrderType}
            setManufacturingOrderType={setManufacturingOrderType}
            manufacturingOrderProgress={manufacturingOrderProgress}
            setManufacturingOrderProgress={setManufacturingOrderProgress}
            filterAssignedMOs={filterAssignedMOs}
            setFilterAssignedMOs={setFilterAssignedMOs}
          />
        }
      />
    </TitanPage>
  );
}
