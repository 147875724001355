import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import debounce from 'lodash.debounce';
import {
  GRID_TREE_DATA_GROUPING_FIELD,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { VIEW_MODES } from './TitanDataGrid';
import { useHistory, useLocation } from 'react-router-dom';

export default function useTitanDataGrid(loadData, options = {}) {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const matchesSM = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const matchesLG = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  const apiRef = useGridApiRef();

  const [viewMode, setViewMode] = React.useState(
    options?.defaultViewMode || VIEW_MODES.TABLE,
  );

  const [loading, setLoading] = React.useState(false);

  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);

  const [page, setPage] = React.useState(options.page ? options.page : 0);
  const [pageSize, setPageSize] = React.useState(
    options.pageSize ? options.pageSize : 25,
  );

  const [search, setSearch] = React.useState(options.search || '');
  const [order, setOrder] = React.useState(
    options.orders ? options.orders : {},
  );

  const [columns, setColumns] = React.useState(
    options.columns ? options.columns : [],
  );

  const [groupingColDef, setGroupingColDef] = React.useState(
    options.groupingColumnInfo
      ? options.groupingColumnInfo.groupingColumn
      : null,
  );

  const [pinnedColumns, setPinnedColumns] = React.useState(
    options.pinnedColumns
      ? {
          left: [GRID_TREE_DATA_GROUPING_FIELD, ...options.pinnedColumns.left],
          right: [...options.pinnedColumns.right],
        }
      : {},
  );

  const axiosAbortControllerRef = React.useRef();
  const loadDataPromiseRef = React.useRef();

  const prepareColumnVisibilityModel = (columns) => {
    const breakpointMatches = {
      sm: matchesSM,
      md: matchesMD,
      lg: matchesLG,
    };

    return columns.reduce(
      (obj, column) => ({
        ...obj,
        [column.field]:
          column.hide !== true
            ? column.visibilityBreakpoint
              ? breakpointMatches[column.visibilityBreakpoint]
              : true
            : false,
      }),
      {},
    );
  };

  const [initialState, setInitialState] = React.useState(
    options.columns
      ? {
          columns: {
            columnVisibilityModel: prepareColumnVisibilityModel(
              options.columns,
            ),
          },
          pinnedColumns:
            (Object.values(
              prepareColumnVisibilityModel(options.columns),
            ).filter((columnValue) => columnValue).length >= 5 &&
              options.pinnedColumns) ||
            (options.groupingColumnInfo && options.pinnedColumns)
              ? {
                  left: [
                    GRID_TREE_DATA_GROUPING_FIELD,
                    ...options.pinnedColumns.left,
                  ],
                  right: [...options.pinnedColumns.right],
                }
              : {},
        }
      : {},
  );

  const [visibilityModel, setVisibilityModel] = React.useState({});

  React.useEffect(() => {
    if (apiRef?.current?.setPinnedColumns) {
      let maxVisibleColumnsWithoutScroll = matchesMD ? 5 : 4;
      if (
        Object.values(visibilityModel).filter((columnValue) => columnValue)
          .length >= maxVisibleColumnsWithoutScroll
      ) {
        apiRef.current.setPinnedColumns(pinnedColumns);
      } else {
        apiRef.current.setPinnedColumns({});
      }
    }
  }, [visibilityModel, matchesSM, matchesMD, matchesLG]);

  React.useEffect(() => {
    if (options.columns && apiRef.current?.setColumnVisibilityModel) {
      apiRef.current.setColumnVisibilityModel(
        prepareColumnVisibilityModel(columns),
      );
    }
  }, [matchesSM, matchesMD, matchesLG, columns]);

  React.useEffect(() => {
    setColumns(options.columns);
  }, [options.columns]);

  const firstLoad = React.useRef(true);

  const prepareRows = (rows) => {
    if (groupingColDef) {
      const {
        groupingColumnInfo: { childrenArrays, nestedChildrenArrays },
      } = options;
      const rowsWithGrouping = [];

      rows.forEach((parentRow) => {
        let childrenArray;

        rowsWithGrouping.push({ ...parentRow, hierarchy: [parentRow.id] });

        if (nestedChildrenArrays) {
          childrenArray = childrenArrays.find((array) => parentRow[array]);
          const nestedChildrenArray = childrenArray
            ? nestedChildrenArrays.find(
                (array) => parentRow[childrenArray][0][array],
              )
            : null;
          if (
            nestedChildrenArray &&
            parentRow[childrenArray][0][nestedChildrenArray].length !== 0
          ) {
            parentRow[childrenArray][0][nestedChildrenArray].forEach(
              (childrenRow) => {
                rowsWithGrouping.push({
                  ...childrenRow,
                  hierarchy: [parentRow.id, childrenRow.id],
                });
              },
            );
          }
        } else {
          childrenArray = childrenArrays.find((array) => parentRow[array]);

          if (childrenArray && parentRow[childrenArray].length !== 0) {
            parentRow[childrenArray].forEach((childrenRow) => {
              rowsWithGrouping.push({
                ...childrenRow,
                hierarchy: [parentRow.id, childrenRow.id],
              });
            });
          }
        }
      });

      return rowsWithGrouping;
    }

    return rows;
  };

  const callLoadData = ({ page, pageSize, search, order }) => {
    if (loadDataPromiseRef.current && axiosAbortControllerRef.current) {
      axiosAbortControllerRef.current.abort();
    }

    setLoading(true);
    setRows([]);

    const params = {
      page: page + 1,
      pageSize,
    };

    if (search) {
      params.search = search;
    }

    if (order) {
      params.order = Object.keys(order).reduce((res, key) => {
        res.push(`${key}:${order[key]}`);

        return res;
      }, []);
    }

    axiosAbortControllerRef.current = new AbortController();

    loadDataPromiseRef.current = loadData(params, {
      signal: axiosAbortControllerRef.current
        ? axiosAbortControllerRef.current.signal
        : null,
    })
      .then((response) => {
        loadDataPromiseRef.current = null;
        setRows(prepareRows(response.data !== undefined ? response.data : []));

        setPage(response.page ? response.page : 0);
        setRowCount(response.totalCount ? response.totalCount : 0);

        setLoading(false);

        if (options.tableRef) {
          options.tableRef.current.data = response.data;
        }
      })
      .catch(() => {});
  };

  const debouncedSearchFunction = React.useCallback(
    debounce(callLoadData, 500),
    [loadData],
  );

  React.useEffect(() => {
    if (options.tableRef) {
      options.tableRef.current = {
        rows: [], // TODO hack
      };
    }

    if (viewMode === VIEW_MODES.GRID) {
      callLoadData({ page, pageSize: 1000, search, order });
    } else {
      callLoadData({ page, pageSize, search, order });
    }
  }, [viewMode, loadData, page, pageSize, order]);

  const reloadData = React.useCallback(() => {
    callLoadData({ page, pageSize, search, order });
  }, [loadData, page, pageSize, order]);

  React.useEffect(() => {
    setSearch(options.search);
  }, [options.search]);

  React.useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }

    debouncedSearchFunction({ page: 0, pageSize, search, order });
  }, [search]);

  React.useEffect(() => {
    if (queryParams.get('search') !== search) {
      setSearch(queryParams.get('search'));
    }
  }, [search, location.search]);

  return {
    apiRef,
    reloadData,

    viewMode,
    setViewMode,

    loading,
    setLoading,

    rows,
    setRows,

    rowCount,
    setRowCount,

    page,
    setPage: (page) => {
      if (options.onChangePage) {
        options.onChangePage(page);
      }

      return setPage(page);
    },

    pageSize,
    setPageSize,

    search,
    setSearch: (search) => {
      setSearch(search);

      history.push({
        pathname: location.pathname,
        search: search ? `?search=${search}` : '',
      });
    },

    order,
    setOrder,

    columns,
    setColumns,

    groupingColDef,
    setGroupingColDef,

    initialState,
    setInitialState,

    visibilityModel,
    setVisibilityModel,

    prepareRows,
  };
}
