import React, { useCallback } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import TitanPopover from '../Titan/TitanPopover';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import CustomFieldService from '../../services/CustomFieldService';
import { FormHelperText } from '@mui/material';

export default function PreformCustomFields({
  customFields,
  entityCustomFields,
  onChange,
}) {
  const renderCustomField = useCallback(
    (customField) => {
      const value = entityCustomFields[customField?.id]?.value;
      const showWarning = CustomFieldService.showCustomFieldWarning(
        customField,
        value,
      );
      const formHelperText = CustomFieldService.prepareCustomFieldFormHelpText(
        customField,
        showWarning,
      );

      switch (customField.type) {
        case 'BOOLEAN':
          return (
            <FormControl>
              <FormLabel>{customField.name}</FormLabel>
              <RadioGroup
                row
                value={value}
                onChange={(e) =>
                  onChange(
                    Object.values({
                      ...entityCustomFields,
                      [customField.id]: {
                        ...entityCustomFields[customField.id],
                        value: e.target.value,
                      },
                    }),
                  )
                }
              >
                <FormControlLabel
                  value="true"
                  control={
                    <Radio color={showWarning ? 'warning' : 'primary'} />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={
                    <Radio color={showWarning ? 'warning' : 'primary'} />
                  }
                  label="No"
                />
              </RadioGroup>
              {formHelperText && (
                <FormHelperText>{formHelperText}</FormHelperText>
              )}
            </FormControl>
          );
        case 'STRING':
        case 'NUMBER':
          return (
            <TextField
              autoFocus
              label={customField.name}
              type={customField.type === 'NUMBER' ? 'number' : 'text'}
              fullWidth
              value={
                entityCustomFields[customField.id]
                  ? entityCustomFields[customField.id].value
                  : ''
              }
              step={customField.type === 'NUMBER' ? '.01' : ''}
              margin="normal"
              variant="outlined"
              onChange={(e) => {
                onChange(
                  Object.values({
                    ...entityCustomFields,
                    [customField.id]: {
                      ...entityCustomFields[customField.id],
                      value: e.target.value,
                    },
                  }),
                );
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {customField.units ? customField.units.toLowerCase() : ''}
                  </InputAdornment>
                ),
              }}
              color={showWarning ? 'warning' : 'primary'}
              helperText={formHelperText}
            />
          );
        case 'SINGLE_SELECT':
          return (
            <FormControl>
              <FormLabel>{customField.name}</FormLabel>
              <RadioGroup
                row
                value={
                  entityCustomFields[customField.id]
                    ? entityCustomFields[customField.id].value
                    : ''
                }
                onChange={(e) =>
                  onChange(
                    Object.values({
                      ...entityCustomFields,
                      [customField.id]: {
                        ...entityCustomFields[customField.id],
                        value: e.target.value,
                      },
                    }),
                  )
                }
              >
                {customField.options
                  ? customField.options.map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={
                          <Radio color={showWarning ? 'warning' : 'primary'} />
                        }
                        label={option}
                      />
                    ))
                  : ''}
              </RadioGroup>
              {formHelperText && (
                <FormHelperText>{formHelperText}</FormHelperText>
              )}
            </FormControl>
          );
        case 'MULTI_SELECT':
          return (
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">{customField.name}</FormLabel>
              <FormGroup>
                {customField.options
                  ? customField.options.map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={
                              entityCustomFields[customField.id]
                                ? entityCustomFields[customField.id].value
                                    .split(', ')
                                    .includes(option)
                                : false
                            }
                            name={option}
                            color={showWarning ? 'warning' : 'primary'}
                            onChange={(e) =>
                              onChange(
                                Object.values({
                                  ...entityCustomFields,
                                  [customField.id]: {
                                    ...entityCustomFields[customField.id],
                                    value: e.target.checked
                                      ? Array.from(
                                          new Set([
                                            ...entityCustomFields[
                                              customField.id
                                            ].value
                                              .split(', ')
                                              .filter((o) => o),
                                            option,
                                          ]),
                                        ).join(', ')
                                      : entityCustomFields[customField.id].value
                                          .split(', ')
                                          .filter((o) => o && o !== option)
                                          .join(', '),
                                  },
                                }),
                              )
                            }
                          />
                        }
                        label={option}
                      />
                    ))
                  : ''}
              </FormGroup>
              {formHelperText && (
                <FormHelperText>{formHelperText}</FormHelperText>
              )}
            </FormControl>
          );
        default:
          break;
      }

      return '';
    },
    [entityCustomFields, onChange],
  );

  return (
    <Box sx={{ alignItems: 'left' }}>
      {customFields.map((customField) => (
        <Box key={customField.id} sx={{ display: 'flex', mb: 2 }}>
          {renderCustomField(customField)}
          {customField.image ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                ml: 1,
              }}
            >
              <TitanPopover icon={InfoOutlinedIcon}>
                <img
                  src={customField.image.url}
                  alt=""
                  style={{ width: '100%' }}
                />
              </TitanPopover>
            </Box>
          ) : (
            ''
          )}
        </Box>
      ))}
    </Box>
  );
}
