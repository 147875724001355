import React from 'react';
import { Typography } from '@mui/material';

export default function FibrifySearchBarNothingFound() {
  return (
    <Typography variang="caption" color="secondary" sx={{ ml: 2 }}>
      nothing found
    </Typography>
  );
}
