import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import BuildPlanService from '../../services/BuildPlanService';
import { ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import FusionPlanService from '../../services/FusionPlanService';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import PreformService from '../../services/PreformService';
import SpoolService from '../../services/SpoolService';
import FinalPartService from '../../services/FinalPartService';
import PreformLink from '../Component/PreformLink';
import FinalPartLink from '../FinalPart/FinalPartLink';
import SpoolLink from '../Spools/SpoolLink';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import FibrifySearchBarNothingFound from './FibrifySearchBarNothingFound';
import debounce from 'lodash.debounce';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { ROUTES } from '../../constants';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

export default function FibrifySearchBar({ setOpenQRCodeScannerDialog }) {
  const [loading, setLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [buildPlans, setBuildPlans] = React.useState([]);
  const [fusionPlans, setFusionPlans] = React.useState([]);
  const [preforms, setPreforms] = React.useState([]);
  const [finalParts, setFinalParts] = React.useState([]);
  const [spools, setSpools] = React.useState([]);

  const loadData = React.useCallback(async () => {
    setLoading(true);

    const params = {
      search,
      pageSize: 3,
      page: 1,
      order: ['created_at:desc'],
    };

    const config = {};

    const [
      { data: buildPlans },
      { data: fusionPlans },
      { data: preforms },
      { data: finalParts },
      { data: spools },
    ] = await Promise.all([
      BuildPlanService.getBuildPlans(params, 'v2', config),
      FusionPlanService.getFusionPlansV2(params, config),
      PreformService.getPreforms(params, config),
      FinalPartService.getFinalParts(params, config),
      SpoolService.getSpools(params, config),
    ]);

    setBuildPlans(buildPlans);
    setFusionPlans(fusionPlans);
    setPreforms(preforms);
    setFinalParts(finalParts);
    setSpools(spools);

    setLoading(false);
  }, [search]);

  const debouncedSearchFunction = React.useCallback(debounce(loadData, 500), [
    loadData,
  ]);

  const handleChange = async (e) => {
    const search = e.target.value;
    setSearch(search);
    setAnchorEl(e.currentTarget);
  };

  React.useEffect(() => {
    if (search.length > 2) {
      debouncedSearchFunction();
    }
  }, [search]);

  const open = search.length > 2 && Boolean(anchorEl);

  const clearSearch = () => {
    setAnchorEl(null);
    setSearch('');
  };

  const onMoreClick = React.useCallback(() => {
    clearSearch();
  }, [clearSearch]);

  return (
    <Box sx={{ width: '300px', mr: 2 }}>
      <Paper
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton type="button" sx={{ p: '10px' }}>
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          onChange={handleChange}
          value={search}
          placeholder="Find anything..."
        />
        {search && (
          <IconButton type="button" sx={{ p: '10px' }} onClick={clearSearch}>
            <CloseIcon />
          </IconButton>
        )}
        <IconButton
          type="button"
          sx={{ p: '10px' }}
          onClick={() => setOpenQRCodeScannerDialog(true)}
        >
          <QrCodeScannerIcon />
        </IconButton>
      </Paper>
      <Popper
        open={open}
        anchorEl={anchorEl}
        onClose={clearSearch}
        placement="bottom-end"
        sx={{ zIndex: 10000 }}
      >
        <Paper
          sx={{
            mt: '10px',
            mr: '-47px',
            width: '500px',
            p: 2,
            bgcolor: 'background.paper',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 60px)',
          }}
        >
          {loading ? (
            <TitanCircularProgress />
          ) : (
            <>
              <List
                subheader={
                  <ListSubheader component="div" disableSticky>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span>Build Plans</span>
                      <Link
                        component={RouterLink}
                        to={[
                          ROUTES.BUILD_PLANS('page', 0),
                          `search=${search}`,
                        ].join('?')}
                        onClick={onMoreClick}
                      >
                        more
                      </Link>
                    </Stack>
                  </ListSubheader>
                }
              >
                {buildPlans.map((buildPlan) => (
                  <ListItemButton key={buildPlan.id}>
                    <BuildPlanLink buildPlan={buildPlan} />
                  </ListItemButton>
                ))}
                {buildPlans.length === 0 && <FibrifySearchBarNothingFound />}
              </List>
              <List
                subheader={
                  <ListSubheader component="div" disableSticky>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span>Fusion Plans</span>
                      <Link
                        component={RouterLink}
                        to={[
                          ROUTES.FUSION_PLANS('page', 0),
                          `search=${search}`,
                        ].join('?')}
                        onClick={onMoreClick}
                      >
                        more
                      </Link>
                    </Stack>
                  </ListSubheader>
                }
              >
                {fusionPlans.map((fusionPlan) => (
                  <ListItemButton key={fusionPlan.id}>
                    <FusionPlanLink fusionPlan={fusionPlan} />
                  </ListItemButton>
                ))}
                {fusionPlans.length === 0 && <FibrifySearchBarNothingFound />}
              </List>
              <List
                subheader={
                  <ListSubheader component="div" disableSticky>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span>Preforms</span>
                      <Link
                        component={RouterLink}
                        to={[
                          ROUTES.HISTORY_ITEMS('preforms', 'page', 0),
                          `search=${search}`,
                        ].join('?')}
                        onClick={onMoreClick}
                      >
                        more
                      </Link>
                    </Stack>
                  </ListSubheader>
                }
              >
                {preforms.map((preform) => (
                  <ListItemButton key={preform.id}>
                    <PreformLink preform={preform} />
                  </ListItemButton>
                ))}
                {preforms.length === 0 && <FibrifySearchBarNothingFound />}
              </List>
              <List
                subheader={
                  <ListSubheader component="div" disableSticky>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span>Final Parts</span>
                      <Link
                        component={RouterLink}
                        to={[
                          ROUTES.HISTORY_ITEMS('final-parts', 'page', 0),
                          `search=${search}`,
                        ].join('?')}
                        onClick={onMoreClick}
                      >
                        more
                      </Link>
                    </Stack>
                  </ListSubheader>
                }
              >
                {finalParts.map((finalPart) => (
                  <ListItemButton key={finalPart.id}>
                    <FinalPartLink finalPart={finalPart} />
                  </ListItemButton>
                ))}
                {finalParts.length === 0 && <FibrifySearchBarNothingFound />}
              </List>
              <List
                subheader={
                  <ListSubheader component="div" disableSticky>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span>Spools</span>
                      <Link
                        component={RouterLink}
                        to={[
                          ROUTES.GEAR_TAB_PAGE('spools', 'page', 0),
                          `search=${search}`,
                        ].join('?')}
                        onClick={onMoreClick}
                      >
                        more
                      </Link>
                    </Stack>
                  </ListSubheader>
                }
              >
                {spools.map((spool) => (
                  <ListItemButton key={spool.id}>
                    <SpoolLink spool={spool} />
                  </ListItemButton>
                ))}
                {spools.length === 0 && <FibrifySearchBarNothingFound />}
              </List>
            </>
          )}
        </Paper>
      </Popper>
    </Box>
  );
}
