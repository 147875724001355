import TitanDialog from '../Titan/TitanDialog';
import TextField from '@mui/material/TextField';
import React from 'react';
import { MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { MATERIAL_TYPES } from '../../constants';
import InputAdornment from '@mui/material/InputAdornment';
import MaterialTypeService from '../../services/MaterialTypeService';
import useFibrifyForm from '../Fibrify/hooks/useFibrifyForm';

export default function MaterialDialog({ onSave, onClose, materialType }) {
  const { onInputWheel } = useFibrifyForm();
  const [name, setName] = React.useState(
    materialType && materialType.name ? materialType.name : '',
  );
  const [nameError, setNameError] = React.useState('');

  const [type, setType] = React.useState(
    materialType ? materialType.type : 'PLASTIC',
  );
  const [minTemperature, setMinTemperature] = React.useState(
    materialType && materialType.minTemperature
      ? materialType.minTemperature
      : '',
  );
  const [maxTemperature, setMaxTemperature] = React.useState(
    materialType && materialType.maxTemperature
      ? materialType.maxTemperature
      : '',
  );
  const [loadTemperature, setLoadTemperature] = React.useState(
    materialType && materialType.loadTemperature
      ? materialType.loadTemperature
      : '',
  );

  const [averageLinearDensity, setAverageLinearDensity] = React.useState(
    materialType && materialType.averageLinearDensity
      ? materialType.averageLinearDensity
      : '',
  );

  const onSubmitForm = React.useCallback(() => {
    return onSave({
      name,
      type,
      minTemperature,
      maxTemperature,
      loadTemperature,
      ...(averageLinearDensity ? { averageLinearDensity } : {}),
    });
  }, [
    name,
    type,
    minTemperature,
    maxTemperature,
    loadTemperature,
    averageLinearDensity,
  ]);

  const checkMaterialNameExists = React.useCallback(async () => {
    if (materialType && materialType.name === name) {
      return;
    }

    setNameError('');

    if (name.length !== 0) {
      const nameExists = await MaterialTypeService.checkNameExists(name);

      if (nameExists) {
        setNameError('Material already exists');
      }
    }
  }, [name, type]);

  return (
    <TitanDialog
      title={`${materialType ? 'Edit' : 'Create'} Material`}
      maxWidth="xs"
      onClose={onClose}
      onSave={onSubmitForm}
      saveButtonLabel={materialType ? 'Save' : 'Create'}
      isValid={
        name.length !== 0 &&
        !nameError &&
        minTemperature &&
        maxTemperature &&
        loadTemperature &&
        maxTemperature >= minTemperature
      }
    >
      <TextField
        required
        label="Name"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
        error={!!nameError}
        helperText={nameError}
        inputProps={{ onBlur: checkMaterialNameExists }}
      />
      <FormControl fullWidth margin="normal">
        <TextField
          select
          label="Type"
          required
          fullWidth
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value={MATERIAL_TYPES.PLASTIC}>Polymer</MenuItem>
          <MenuItem value={MATERIAL_TYPES.FIBER}>Fiber</MenuItem>
        </TextField>
      </FormControl>
      <TextField
        type="number"
        required
        label="Min temperature"
        placeholder="Min temperature"
        value={minTemperature}
        onChange={(e) => setMinTemperature(parseInt(e.target.value, 10))}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: <InputAdornment position="end">°C</InputAdornment>,
        }}
        onWheel={onInputWheel}
      />
      <TextField
        type="number"
        required
        label="Max temperature"
        placeholder="Max temperature"
        value={maxTemperature}
        onChange={(e) => setMaxTemperature(parseInt(e.target.value, 10))}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: <InputAdornment position="end">°C</InputAdornment>,
        }}
        error={
          !!(
            minTemperature &&
            maxTemperature &&
            maxTemperature < minTemperature
          )
        }
        helperText={
          minTemperature && maxTemperature && maxTemperature < minTemperature
            ? 'Max temperature should be greater than min temperature'
            : ''
        }
        onWheel={onInputWheel}
      />
      <TextField
        type="number"
        required
        label="Load temperature"
        placeholder="Load temperature"
        value={loadTemperature}
        onChange={(e) => setLoadTemperature(parseInt(e.target.value, 10))}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: <InputAdornment position="end">°C</InputAdornment>,
        }}
        onWheel={onInputWheel}
      />
      <TextField
        type="number"
        label="Average linear density"
        placeholder="Average linear density"
        value={averageLinearDensity}
        onChange={(e) => {
          setAverageLinearDensity(e.target.value);
        }}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: <InputAdornment position="end">g/m</InputAdornment>,
        }}
        onWheel={onInputWheel}
      />
    </TitanDialog>
  );
}
