import React, { useContext } from 'react';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import useWebSocket from 'react-use-websocket';
import ROUTES from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import SnackbarWithLink from './SnackbarWithLink';
import OrganizationService from '../../services/OrganizationService';
import { useAuth0 } from '../Authentication/Auth0';
import useAsyncEffect from 'use-async-effect';
import useMeasuringDevices from '../Fibrify/hooks/useMeasuringDevices';

export const TitanContext = React.createContext();
export const useTitan = () => useContext(TitanContext);

export function TitanProvider(props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [apiVersion, setApiVersion] = React.useState('');
  const [organization, setOrganization] = React.useState({
    measuringDevicesEnabled: false,
    metabaseIntegrationEnabled: false,
    odooIntegrationEnabled: false,
    labelPrinterEnabled: false,
  });
  const [organizationLogo, setOrganizationLogo] = React.useState('');
  const [odooIntegrationEnabled, setOdooIntegrationEnabled] =
    React.useState(false);
  const [appInsights, setAppInsights] = React.useState();
  const shownSessionExpiredMessage = React.useRef(false);
  const { profile, isAuthenticated, accessToken, expiresAt } = useAuth0();

  const websocketReconnectRef = React.useRef(false);

  const [pageHistory, setPageHistory] = React.useState(() => {
    const savedHistory = localStorage.getItem('pageHistory');
    const parsedData = JSON.parse(savedHistory);
    return parsedData
      ? parsedData
      : [
          {
            id: 'DASHBOARD',
            url: ROUTES.DASHBOARD,
            label: 'Dashboard',
          },
        ];
  });

  const [activePage, setActivePage] = React.useState(pageHistory.length - 1);

  const backToPreviousPage = React.useCallback(
    (location) => {
      history.push({
        pathname: pageHistory[activePage - 1].url,
        state: { from: location.pathname },
      });
      setActivePage(activePage - 1);
    },
    [activePage, pageHistory],
  );

  const addPageToPageHistory = React.useCallback(
    ({ id, url, label, addInfo = {} }) => {
      setPageHistory((prev) => {
        const newHistoryPage = [...prev];

        const pageHistoryWithUniquePages = newHistoryPage.filter(
          (page) => page.id !== id,
        );

        localStorage.setItem(
          'pageHistory',
          JSON.stringify([
            ...pageHistoryWithUniquePages,
            { id, url, label, addInfo },
          ]),
        );
        setActivePage(pageHistoryWithUniquePages.length);
        return [...pageHistoryWithUniquePages, { id, url, label, addInfo }];
      });
    },
    [setPageHistory, setActivePage],
  );

  const copyValueToClipboard = React.useCallback(
    async (value, label) => {
      const capitalizedTitleForPushMessage = () =>
        label.charAt(0).toUpperCase() + label.slice(1);

      try {
        await navigator.clipboard.writeText(value);

        enqueueSnackbar(
          label
            ? `${capitalizedTitleForPushMessage()} has been copied to your clipboard!`
            : `Copied to clipboard!`,
          {
            variant: 'success',
          },
        );
      } catch (error) {
        console.warn(error);

        enqueueSnackbar(
          label
            ? `Sorry, we couldn't copy ${label} to your clipboard. You can do it manually: ${value}`
            : `Not copied to clipboard. You can do it manually: ${value}`,
          {
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar],
  );

  const preparePageObjectForLink = (
    page,
    id,
    name,
    route,
    tab,
    addInfo = {},
  ) => {
    return {
      id: `${page}:${id}`,
      url: route,
      label: `${name} | ${tab}`,
      addInfo: addInfo,
    };
  };

  React.useEffect(() => {
    Axios.interceptors.response.use(
      (res) => res,
      (error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        } else if (error.response && error.response.status === 401) {
          if (!shownSessionExpiredMessage.current) {
            enqueueSnackbar('Session expired. Please login again', {
              variant: 'error',
            });
            shownSessionExpiredMessage.current = true;
            window.localStorage.setItem(
              'lastActivePath',
              window.location.pathname,
            );
            history.push(ROUTES.WELCOME);
          }
        } else if (error.code === 'ERR_CANCELED') {
          return Promise.reject(error);
        } else {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }

        return Promise.reject(error);
      },
    );

    if (
      process.env.REACT_APP_AZURE_INSTRUMENTATION_KEY &&
      process.env.REACT_APP_AZURE_CONNECTION_STRING
    ) {
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: process.env.REACT_APP_AZURE_INSTRUMENTATION_KEY,
          connectionString: process.env.REACT_APP_AZURE_CONNECTION_STRING,
        },
      });
      appInsights.loadAppInsights();
      setAppInsights(appInsights);
    }
  }, []);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/version`).then((res) => {
      if (res.data && res.data.version) {
        setApiVersion(res.data.version);
      }
    });
  }, []);

  useAsyncEffect(async () => {
    if (!profile) {
      return;
    }

    const organizationId = profile.loggedAs
      ? profile.loggedAsMember.organizationId
      : profile.organizationId;

    if (profile && organizationId) {
      const organization = await OrganizationService.getOrganizationById(
        organizationId,
      );

      if (organization) {
        setOrganization(organization);

        if (organization.logoUrl) {
          setOrganizationLogo(organization.logoUrl);
        }
        setOdooIntegrationEnabled(organization.odooIntegrationEnabled || false);
      }
    }
  }, [profile]);

  const prepareWebSocketUrl = (token) => {
    const webSocketUrl = new URL(process.env.REACT_APP_FIBRIFY_WEB_SOCKET_URL);
    webSocketUrl.searchParams.append('token', token);

    return webSocketUrl.toString();
  };

  const getWebSocketUrl = React.useCallback(
    () =>
      new Promise((resolve, reject) => {
        if (accessToken && expiresAt && new Date().getTime() < expiresAt) {
          resolve(prepareWebSocketUrl(accessToken));
        } else {
          const interval = setInterval(() => {
            if (accessToken && expiresAt && new Date().getTime() < expiresAt) {
              clearInterval(interval);
              resolve(prepareWebSocketUrl(accessToken));
            }
          }, 1000);
        }
      }),
    [accessToken, expiresAt],
  );

  const getWebSocketOptions = React.useCallback(
    (withReconnectOptions = false) => {
      let options = {
        share: true,
      };

      if (withReconnectOptions) {
        options = {
          ...options,
          retryOnError: true,
          reconnectAttempts: 10,
          reconnectInterval: 1000,
          shouldReconnect: async () => {
            if (
              isAuthenticated &&
              expiresAt &&
              new Date().getTime() < expiresAt
            ) {
              console.log('Reconnecting to fibrify websocket');
              websocketReconnectRef.current = true;

              return true;
            }

            return false;
          },
          onOpen: () => {
            if (websocketReconnectRef.current) {
              enqueueSnackbar('Connected to websocket', { variant: 'success' });
              websocketReconnectRef.current = false;
            }

            console.log('Connected to fibrify websocket');
          },
          onClose: () => {
            enqueueSnackbar(
              'Disconnected from fibrify websocket. Reconnecting...',
              {
                variant: 'error',
              },
            );

            console.log('Disconnected from mytaverse websocket');
          },
          onError: (event) => {
            enqueueSnackbar('Websocket Error...', { variant: 'error' });

            console.error(`Fibrify websocket error: ${JSON.stringify(event)}`);
          },
        };
      }

      return options;
    },
    [isAuthenticated, expiresAt],
  );

  useWebSocket(getWebSocketUrl, getWebSocketOptions(true));

  const { loadingMeasuringDevices, measuringDevices } = useMeasuringDevices({
    getWebSocketUrl,
    getWebSocketOptions,
  });

  return (
    <TitanContext.Provider
      value={{
        pushSnackbar: (message = '', options = {}) =>
          enqueueSnackbar(message, options),
        pushCustomSnackbar: (
          message = '',
          options = {
            content: <SnackbarWithLink message={message} />,
          },
        ) => enqueueSnackbar('', options),
        copyValueToClipboard,
        apiVersion,
        appInsights,
        organizationLogo,
        odooIntegrationEnabled,
        pageHistory,
        preparePageObjectForLink,
        addPageToPageHistory,
        activePage,
        setActivePage,
        backToPreviousPage,

        getWebSocketUrl,
        getWebSocketOptions,

        loadingMeasuringDevices,
        measuringDevices,

        organization,
      }}
    >
      {props.children}
    </TitanContext.Provider>
  );
}
