import MeasuringDeviceService from '../../services/MeasuringDeviceService';
import React, { useCallback } from 'react';
import TitanCircularProgress from '../Titan/TitanCircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { useTitan } from '../Titan/Titan';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import FibrifyMeasurement from '../Fibrify/FibrifyMeasurement';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import nanoid from 'nanoid';

export default function MeasuringDevicesPanel() {
  const { copyValueToClipboard } = useTitan();
  const { loadingMeasuringDevices, measuringDevices } = useTitan();

  const measure = useCallback(async (measuringDeviceId) => {
    await MeasuringDeviceService.measure(measuringDeviceId);
  }, []);

  const renderKeyenceMeasurements = useCallback((measurements) => {
    if (!measurements.measuredValues) {
      return '-';
    }

    const measuredValues = measurements.measuredValues
      .sort((a, b) => a.OrdinalNumber - b.OrdinalNumber)
      .map((measurement) => ({
        id: nanoid(),
        measurements: Object.entries(measurement)
          .filter(
            ([measurementLabel]) =>
              !['Number', 'OrdinalNumber'].includes(measurementLabel),
          )
          .map(([measurementLabel, measurementValue]) => ({
            id: nanoid(),
            label: measurementLabel,
            value: measurementValue,
          })),
      }));

    return (
      <Stack spacing={1} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
        {measuredValues.map((measurements) => (
          <Stack spacing={1} key={measurements.id}>
            {measurements.measurements.map((measurement) => (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                key={measurement.id}
              >
                <FibrifyMeasurement
                  label={measurement.label}
                  units="mm"
                  value={measurement.value}
                />
                <IconButton
                  onClick={() =>
                    copyValueToClipboard(measurement.value, measurement.label)
                  }
                >
                  <ContentCopyIcon />
                </IconButton>
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    );
  }, []);

  const renderScaleMeasurements = useCallback((weight) => {
    return (
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <FibrifyMeasurement label="Weight" units="g" value={weight} />
        <IconButton onClick={() => copyValueToClipboard(weight, 'Weight')}>
          <ContentCopyIcon />
        </IconButton>
      </Stack>
    );
  }, []);

  const renderMeasurements = useCallback(
    (measuringDevice) => {
      if (!measuringDevice.measurements) {
        return '-';
      }

      switch (measuringDevice.deviceType) {
        case 'SCALE':
          return renderScaleMeasurements(measuringDevice.measurements);
        case 'KEYENCE':
          return renderKeyenceMeasurements(measuringDevice.measurements);
        default:
          break;
      }
    },
    [renderScaleMeasurements, renderKeyenceMeasurements],
  );

  return (
    <Box sx={{ p: 2 }}>
      {loadingMeasuringDevices ? (
        <TitanCircularProgress />
      ) : (
        <Box>
          {measuringDevices.map((measuringDevice) => (
            <Box sx={{ mb: 2 }} key={measuringDevice.id}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Typography variant="caption">
                  {measuringDevice.name}
                </Typography>
                <LoadingButton
                  size="small"
                  variant="contained"
                  onClick={() => measure(measuringDevice.id)}
                >
                  Measure
                </LoadingButton>
              </Stack>

              {renderMeasurements(measuringDevice)}

              <Divider sx={{ mb: 2, mt: 2 }} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
