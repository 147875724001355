import React from 'react';
import { COMPONENT_STATUSES } from '../../constants';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useTitan } from '../Titan/Titan';
import Stack from '@mui/material/Stack';

export default function FinalPartActions({
  finalPart,
  onFused,
  onFusionScrap,
  onPrintLabel,
}) {
  const {
    organization: { labelPrinterEnabled = false },
  } = useTitan();

  const [actions, setActions] = React.useState([]);
  const [finalPartToFused, setFinalPartToFused] = React.useState();
  const [finalPartToFusionScrap, setFinalPartToFusionScrap] = React.useState();
  const [finalPartToPrintLabel, setFinalPartToPrintLabel] = React.useState();

  React.useEffect(() => {
    const actions = [];

    if ([COMPONENT_STATUSES.FUSION_SCRAP].includes(finalPart.status)) {
      actions.push({
        color: 'secondary',
        variant: 'outlined',
        label: 'Set as Fused',
        onClick: () => setFinalPartToFused(finalPart),
      });
    }

    if (labelPrinterEnabled && onPrintLabel) {
      actions.push({
        color: 'primary',
        variant: 'outlined',
        label: 'Print label',
        icon: <PrintIcon />,
        onClick: () => setFinalPartToPrintLabel(finalPart),
      });
    }

    if (finalPart.status === COMPONENT_STATUSES.FUSED) {
      actions.push({
        color: 'primary',
        variant: 'outlined',
        label: 'Mark as fusion scrap',
        onClick: () => setFinalPartToPrintLabel(finalPart),
      });
    }

    setActions(actions);
  }, [finalPart]);

  if (actions.length === 0) {
    return '';
  }

  return (
    <>
      {actions.length ? (
        <Stack direction="row" spacing={2}>
          {actions.map((action, index) => (
            <Button
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                action.onClick(e);
              }}
              variant={action.variant}
              color={action.color}
              startIcon={action.icon}
            >
              {action.label}
            </Button>
          ))}
        </Stack>
      ) : (
        ''
      )}

      {finalPartToFused && (
        <TitanConfirmationDialog
          title={`Set final part ${finalPart.id} as fused`}
          message={`Are you sure you want to set final part ${finalPart.id} as fused?`}
          onConfirm={async () => {
            await onFused(finalPart);
          }}
          onClose={() => setFinalPartToFused(null)}
        />
      )}

      {finalPartToFusionScrap && (
        <TitanConfirmationDialog
          title={`Mark final part ${finalPart.id} as fusion scrap`}
          message={`Are you sure you want to mark final part ${finalPart.id} as fusion scrap?`}
          onConfirm={async () => {
            await onFusionScrap(finalPart);
          }}
          onClose={() => setFinalPartToFusionScrap(null)}
        />
      )}

      {finalPartToPrintLabel && (
        <TitanConfirmationDialog
          title={`Print label for ${finalPart.id} final part`}
          message={`Are you sure you want to print label for ${finalPart.id} final part?`}
          onConfirm={async () => {
            await onPrintLabel(finalPart);
          }}
          onClose={() => setFinalPartToPrintLabel(null)}
        />
      )}
    </>
  );
}
